<template>
	<div class="pui-form">
		<mlocalidad-modals :modelName="modelName"></mlocalidad-modals>
		<!--<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<mlocalidad-form-header
				:modelPk="modelPk"
			></mlocalidad-form-header>
		</pui-form-header>-->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set :title="getTitleDatos">
						<v-layout wrap>
							<!-- IDLOCALIDAD -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idlocalidad-mlocalidad`"
									v-model="model.idlocalidad"
									:label="$t('mlocalidad.idlocalidad')"
									disabled
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<!-- CODLOCODE -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`codlocode-mlocalidad`"
									v-model="model.codlocode"
									:label="$t('mlocalidad.codlocode')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- NAME -->
							<v-flex xs12 md6>
								<pui-text-field
									:id="`name-mlocalidad`"
									v-model="model.name"
									:label="$t('mlocalidad.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-flex>
							<!-- ESPUERTO -->
							<v-flex xs12 md2 align-self-center>
								<pui-checkbox
									:id="`espuerto-mlocalidad`"
									:label="$t('mlocalidad.espuerto')"
									v-model="model.espuerto"
									true-value="1"
									false-value="0"
								></pui-checkbox>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- IDPAIS -->
							<v-flex xs12 md4>
								<pui-select
									id="idpais"
									attach="idpais"
									:label="$t('mlocalidad.idpais')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmpais"
									:itemsToSelect="[{ codigo: model.idpais }]"
									:modelFormMapping="{ codigo: 'idpais' }"
									itemValue="codigo"
									itemText="descripcion"
									:order="{ descripcion: 'asc' }"
								></pui-select>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- FECINIVIGENCIA -->
							<v-flex xs12 md3>
								<pui-date-field
									:id="`fecinivigencia-mlocalidad`"
									v-model="model.fecinivigencia"
									:label="$t('mlocalidad.fecinivigencia')"
									:disabled="formDisabled"
									toplabel
									time
									required
								></pui-date-field>
							</v-flex>
							<!-- FECFINVIGENCIA -->
							<v-flex xs12 md3>
								<pui-date-field
									:id="`fecfinvigencia-mlocalidad`"
									v-model="model.fecfinvigencia"
									:label="$t('mlocalidad.fecfinvigencia')"
									:disabled="formDisabled"
									toplabel
									time
									:min="model.fecinivigencia"
								></pui-date-field>
							</v-flex>
						</v-layout>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mlocalidadActions from './MlocalidadActions';
import mlocalidadModals from './MlocalidadModals.vue';

export default {
	name: 'mlocalidad-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mlocalidad-modals': mlocalidadModals
	},
	data() {
		return {
			modelName: 'mlocalidad',
			actions: mlocalidadActions.formActions
		};
	},
	methods: {
		afterGetData() {
			if (!this.model.espuerto) {
				this.model.espuerto = 0;
			}
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mtiporesolucion');
		}
	},
	created() {}
};
</script>
